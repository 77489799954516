<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else class="content-form">
 
      <div class="mx-3 content-news-detail">
        <div v-html="description" class="px-2 pb-3"></div>
      </div>
    </div>
    <b-row class="footer">
      <div class="text-center w-100">
        <b-button
          @click.prevent="nextToForm"
          class="w-100 btn-gray"
          :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
          >ลงทะเบียนเข้าร่วมกิจกรรม</b-button
        >
      </div>
    </b-row>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: {
    Breadcrumb,
    OtherLoading
  },
  data() {
    return {
      urlKey: this.$route.query.urlKey,
      description: "",
      breadcrumb: [
        {
          text: "ลงทะเบียนเข้าร่วมกิจกรรม",
          to: "/profile"
        }
      ],
      isLoading: true,
      linkForm: this.$route.query.path
    };
  },
  async created() {
    await this.getCampaignForm();
  },
  methods: {
    async getCampaignForm() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/Form/${this.urlKey}`)
        .then(async data => {
          if (data.result == 1) {
            this.isLoading = false;
            this.description = data.detail.result.description;
          }
        });
    },
    nextToForm() {
      window.location.href = this.linkForm + `&guid=${this.$route.query.guid}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.content-form {
  margin-top: 80px;
  margin-bottom: 100px;
}
.content-news-detail {
  min-height: 100vw;
  background-color: #fff;
  border-radius: 5px;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 15px;
  right: 0;
  padding: 10px;
  background: #fff;
}
.btn-gray {
  background: #000;
  border-color: transparent;
  color: #fff;
}
::v-deep p img {
  width: 100%;
}
</style>
